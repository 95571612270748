import React, { useState } from "react"

import Container from "components/Container"
import Title from "components/Title"
import { normalizeString } from "utils/strings"
import styled from "styled-components"

const Wrapper = styled.div<{ opened?: boolean }>`
  padding: 40px 0;
  position: fixed;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  left: 0;
  z-index: 1000;
  top: 0;
  bottom: 0;
  width: ${props => (props.opened ? "calc(160px * 2)" : "160px")};
  background-color: white;
  transition: 0.2s all linear;
  ${props =>
    props.opened &&
    `
    & a {
      flex:0 0 80px;
      box-sizing: border-box;
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      & > div {
        height: 80px;
        width: 80px;
      }
    }
    & > div {
      display: flex;
      flex-wrap: wrap;
    }
  `}
`

const ItemsWrapper = styled.div`
  width: 100%;
  overflow: scroll;
  height: 100vh;
  position: relative;
  z-index: 100;
  background: white;
`
const ArrowButton = styled.button`
  height: 45px;
  width: 45px;
  border-radius: 0 20px 20px 0;
  background: white;
  text-align: center;
  font-family: "Roboto Condensed";
  position: absolute;
  top: 0;
  bottom: 0;
  right: -45px;
  margin: auto;
  font-size: 20px;
  border: none;
  font-weight: 100;
  z-index: 50;
  outline: none;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.45);
  cursor: pointer;
  &:hover {
    color: black;
  }
`
const ItemImage = styled.div`
  background-size: cover;
  background-position: center;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  position: relative;
  margin: auto;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.35);
  }
`

const Item = styled.a`
  text-decoration: none;
  color: gray;
  display: inline-block;
  position: relative;
  text-align: center;
  cursor: pointer;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 25px;
  &:hover {
    color: black;
  }
  &:hover ${ItemImage} {
    &:after {
      background: none;
    }
  }
`

const ItemTitle = styled.h5`
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  margin: auto;
  padding: 0;
`

const Categories = ({ items }) => {
  const [opened, setOpened] = useState(false)
  if (!items) return <div></div>
  return (
    <Wrapper opened={opened}>
      <ArrowButton onClick={() => setOpened(!opened)}>
        {opened ? "ᐊ" : "ᐅ"}
      </ArrowButton>
      <ItemsWrapper>
        {items.map(category => (
          <Item href={`#${normalizeString(category.Name)}`} key={category.Name}>
            <ItemImage
              style={{
                backgroundImage: `url(${category.Image?.url})`,
              }}
            />
            <ItemTitle>{category.Name}</ItemTitle>
          </Item>
        ))}
      </ItemsWrapper>
    </Wrapper>
  )
}

export default Categories
