import { gql } from "@apollo/client"

export const JUMBOTRON_QUERY = gql`
  query {
    jumbotrons {
      Title
      Image {
        url
      }
      Description
    }
  }
`
