import React, { useEffect, useState } from "react"

import Categories from "components/Categories"
import JumbotronLogo from "images/novelogo.jpeg"
import styled from "styled-components"

const Wrapper = styled.header<{ fixed?: boolean }>`
  position: ${props => (props.fixed ? "fixed" : "relative")};
  background: #f3f8da;
  z-index: 1000;
  box-shadow: inset 1px 1px 7px rgba(0, 0, 0, 0.15)
    ${props =>
      props.fixed &&
      `
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 125px;
  & > div {
    padding: 20px 0;
  }
`};
`

const ContentWrapper = styled.div<{ compact?: boolean }>`
  max-width: 1280px;
  margin: auto;
  display: flex;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  ${props =>
    !props.compact &&
    `
      flex-direction: column;
  align-items: center;
  justify-content: center;
  `}
`

const LogoWrapper = styled.div<{ compact?: boolean }>`
  background: white;
  padding: 15px;
  border-radius: 100%;
  flex: 0 0 ${props => (props.compact ? "50px" : "250px")};
  height: ${props => (props.compact ? "50px" : "250px")};
  width: ${props => (props.compact ? "50px" : "250px")};
  position: relative;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  transition: 0.2s all ease-in-out;
  overflow: hidden;
`

const Logo = styled.img`
  max-height: 100%;
`

const ListWrapper = styled.div`
  padding: 35px 0;
`
const List = styled.ul`
  margin: 0;
`

const ListItem = styled.li`
  display: inline-block;
  background: white;
  padding: 10px 15px;
  font-size: 15px;
  border-radius: 5px;
  margin: 0 15px;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  cursor: pointer;
`

type HeaderProps = {}

const Header: React.FC<HeaderProps> = () => {
  const [scrolled, setScrolled] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const handleScroll = () => {
    if (window && window.scrollY === 0) {
      setScrolled(false)
    }

    if (window && window.scrollY !== 0 && !scrolled) {
      setScrolled(true)
    }
  }
  return (
    <>
      {scrolled && <div style={{ height: 155 }}></div>}
      <Wrapper fixed={scrolled}>
        <ContentWrapper compact={scrolled}>
          <LogoWrapper compact={scrolled}>
            <Logo src={JumbotronLogo} />
          </LogoWrapper>
          <ListWrapper>
            <ListItem>O nás</ListItem>
            <ListItem>O materiáloch ktoré požívame</ListItem>
            <ListItem>Kontakt</ListItem>
          </ListWrapper>
        </ContentWrapper>
      </Wrapper>
    </>
  )
}

export default Header
