import React, { useState } from "react"

import { getImageUrl } from "utils/images"
import styled from "styled-components"

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
`

type ImageWrapperProps = {
  height: number
}

const ImageWrapper = styled.div<ImageWrapperProps>`
  width: 100%;
  height: ${props => props.height}px;
  background-position: center;
  background-size: cover;
`

const ControlWrapper = styled.div`
  position: absolute;
  bottom: 25px;
  right: 0;
  left: 0;
  width: 90px;
  cursor: pointer;
  margin: auto;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  overflow: hidden;
`

const ControlArrow = styled.div`
  width: 50%;
  height: 50px;
  background: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  font-size: 20px;
  font-weight: 100;
  &:hover {
    background: lightgrey;
  }
`

type CarouselProps = {
  height: number
  images: string[]
}

const Carousel: React.FC<CarouselProps> = ({ height, images }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const handleChangeImage = direction => {
    const imagesCount = images.length - 1
    const nextExists = activeIndex < imagesCount
    const previousExists = activeIndex > 0

    switch (direction) {
      case "previous":
        if (previousExists) setActiveIndex(activeIndex - 1)
        else setActiveIndex(imagesCount)
        break
      case "next":
        if (nextExists) setActiveIndex(activeIndex + 1)
        else setActiveIndex(0)
        break
    }
  }

  return (
    <Wrapper>
      <ImageWrapper
        height={height}
        style={{
          backgroundImage: `url(${images[activeIndex]?.url})`,
        }}
      />
      <ControlWrapper>
        <ControlArrow onClick={() => handleChangeImage("previous")}>
          {"ᐊ"}
        </ControlArrow>
        <ControlArrow onClick={() => handleChangeImage("next")}>
          {"ᐅ"}
        </ControlArrow>
      </ControlWrapper>
    </Wrapper>
  )
}

export default Carousel
