import { useEffect, useState } from "react"

import Carousel from "components/Carousel"
import Container from "components/Container"
import React from "react"
import { normalizeString } from "utils/strings"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Row = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  flex: 0 0 50%;
  @media only screen and (max-width: 430px) {
    flex-direction: column;
    padding: 15px;
  }
`

const Section = styled.div`
  padding: 15px;
  text-align: center;
`

const ContentWrapper = styled.div`
  padding-left: 25px;
  @media only screen and (max-width: 430px) {
    padding: 0;
    text-align: center;
  }
`

const Title = styled.h2`
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  margin: 0 0 30px 0;
  text-align: left;
  text-transform: uppercase;
  @media only screen and (max-width: 430px) {
    margin: 15px 0;
    text-align: center;
  }
`

const Description = styled.p`
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  margin: 0;
  text-align: left;
  padding: 0;
  @media only screen and (max-width: 430px) {
    margin: 15px 0;
    text-align: center;
  }
`

const SectionTitle = styled.h1`
  display: inline-block;
  font-family: "Poppins", sans-serif;
  padding: 15px 50px;
  font-size: 80px;
  text-transform: uppercase;
  font-weight: 700;
  position: absolute;
  left: 30px;
  bottom: 100px;
  z-index: 100;
  margin: auto;
  height: 60px;
`

const SectionTitleWrapper = styled.div`
  max-width: 1240px;
  width: 100%;
  border-radius: 20px 20px 0 0;
  height: 500px;
  position: relative;
  margin: 40px auto;
`

const TitleBackground = styled.div<{ image: string }>`
  background-image: url(${props => props.image});
  background-size: 100% auto;
  background-position: center;
  height: 100%;
  width: 80%;
  z-index: 10;
  position: relative;
  margin-left: auto;
  overflow: hidden;
  &:before {
    content: "";
    position: absolute;
    left: -10%;
    top: -50%;
    right: 0;
    height: 200%;
    width: 200px;
    background: white;
    transform: rotate(10deg);
  }
`

const SectionTitleOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.45);
`

const InterestButton = styled.button`
  height: 60px;
  border: 2px solid pink;
  color: pink;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  margin: 20px 0;
  background: none;
  font-weight: 700;
  padding: 0 25px;
  &:hover {
    color: white;
    background: pink;
    cursor: pointer;
  }

  @media only screen and (max-width: 430px) {
    margin: 25px auto;
    text-align: center;
  }
`

const ProductsList = ({ items }) => {
  const [windowWidth, setWindowWidth] = useState(null)

  useEffect(() => {
    const width = window.innerWidth

    if (width) {
      setWindowWidth(width)
    }
  }, [])

  const handleInterest = name => {
    const messengerInput = document.querySelector("input")
  }

  return (
    <>
      {items?.map(category => (
        <React.Fragment
          key={category.Name + Math.floor(Math.random() * (100000 - 1 + 1)) + 1}
        >
          <SectionTitleWrapper>
            <TitleBackground image={category.Image.url}>
              <SectionTitleOverlay />
            </TitleBackground>
            <SectionTitle id={normalizeString(category.Name)}>
              {category.Name}
            </SectionTitle>
          </SectionTitleWrapper>

          <Container>
            <Wrapper>
              {category?.Products?.map(product => (
                <Row
                  key={
                    product.Name +
                    Math.floor(Math.random() * (100000 - 1 + 1)) +
                    1
                  }
                >
                  <Section>
                    <Carousel
                      height={windowWidth > 430 ? 500 : 300}
                      images={product.Images}
                    />
                  </Section>
                  <Section>
                    <ContentWrapper>
                      <Title>{product.Name}</Title>
                      <Description>{product.Description}</Description>
                    </ContentWrapper>
                    <InterestButton>Zobraziť viac</InterestButton>
                  </Section>
                </Row>
              ))}
            </Wrapper>
          </Container>
        </React.Fragment>
      ))}
    </>
  )
}

export default ProductsList
