import "normalize.css"

import React, { useEffect, useState } from "react"
import { gql, useQuery } from "@apollo/client"
import { graphql, useStaticQuery } from "gatsby"

import Categories from "components/Categories"
import Header from "components/Header"
import { Helmet } from "react-helmet"
import { JUMBOTRON_QUERY } from "apollo/queries/jubmotron"
import ProductsList from "components/ProductsList"
import styled from "styled-components"

const CategoriesWrapper = styled.div`
  z-index: 1000;
  position: relative;
`
const ContentWrapper = styled.div`
  width: calc(100vw - 160px);
  margin: 0 0 0 auto;
`

const IndexPage = props => {
  const data = useStaticQuery(graphql`
    query {
      strapi {
        categories {
          Name
          Image {
            url
          }
          Products(sort: "published_at") {
            Name
            Description
            Images {
              url
            }
          }
        }
      }
    }
  `)

  const jumbotrons = useQuery(JUMBOTRON_QUERY)

  useEffect(() => {
    window.fbAsyncInit = function () {
      FB.init({
        xfbml: true,
        version: "v9.0",
      })
    }
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      js.src = "https://connect.facebook.net/sk_SK/sdk/xfbml.customerchat.js"
      fjs.parentNode.insertBefore(js, fjs)
    })(document, "script", "facebook-jssdk")
  })

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Marra - s dušou prírody</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="Originálne ručné háčkované a pletené výrobky z prírodných materiálov ako sú merino, hodváb, angora a bavlna, ktoré dodajú každému priestoru dušu."
        />
      </Helmet>
      <Header />
      <CategoriesWrapper>
        <Categories items={data?.strapi?.categories} />
      </CategoriesWrapper>
      <ContentWrapper>
        <ProductsList items={data?.strapi?.categories} />
      </ContentWrapper>

      <div id="fb-root"></div>
      <script></script>
      <div
        className="fb-customerchat"
        attribution="setup_tool"
        page_id="105747888148158"
        theme_color="#fa3c4c"
        logged_in_greeting="Neváhaj nám napísať :-)"
        logged_out_greeting="Neváhaj nám napísať :-)"
      ></div>
    </>
  )
}

export default IndexPage
